<div id="page">
  <header>
    <div class="wrapper">
      <img src="assets/brand.png" alt="DIPENDRAGURUNG.COM.NP">
      <h1>SOFTWARE ENGINEER / IT CONSULTANT</h1>
    </div>
  </header>
  
  <div id="content">
    <div class="wrapper">
        <router-outlet></router-outlet>
    </div>
  </div>

  <footer>
    <div class="wrapper">
      <p>&copy; {{ getFullYear() }} DIPENDRAGURUNG.COM.NP | ALL RIGHTS RESERVED.</p>
    </div>
  </footer>
</div>