<section>
    <h3>Mailing Address</h3>
    <p>
        PO Box. 59, <br>
        Pokhara, Nepal <br>
        ZIP - 33700
    </p>

    <br>

    <h3>Email Address</h3>
    <p>mail@dipendragurung.com.np</p>

    <br>

    <h3>Social Links</h3>
    <ul class="links">
        <li>
            <a target="_blank" href="https://np.linkedin.com/in/dipengrg">
            <i class="fab fa-linkedin"></i>
            </a>
        </li>

        <li>
            <a target="_blank" href="https://www.twitter.com/dipen_grg">
            <i class="fab fa-twitter"></i>
            </a>
        </li>

        <li>
            <a target="_blank" href="https://www.instagram.com/_dipen_grg_/">
            <i class="fab fa-instagram"></i>
            </a>
        </li>
    </ul>
</section>